import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  NavLink,
} from "react-router-dom";

import React, { useState, lazy, useEffect } from "react";
const AgencyLoginPage = lazy(() => import("./Agency/AgencyLoginPage"));
const AgencySignupPage = lazy(() => import("./Agency/signupPage"));
const ChatbotFaq = lazy(() => import("./ChatbotFaq"));
//agency
const AgencyDashboard = lazy(() => import("./Agency/AgencyDashboard"));
const AgencyHeader = lazy(() => import("./Agency/AgencyHeader"));
const AgencySideBar = lazy(() => import("./Agency/AgencySideBar"));
const AgencyServiceRequest = lazy(() =>
  import("./Agency/AgencyServiceRequest")
);
const AddNewRequest = lazy(() =>
  import("./Agency/components/ServiceRequest/AddNewRequest")
);
const AgencyNurses = lazy(() => import("./Agency/AgencyNurses"));
const NursesNewRequest = lazy(() =>
  import("./Agency/components/Nurses/NursesNewRequest")
);
const AgencyCharting = lazy(() => import("./Agency/AgencyCharting"));
const DischargeSummaryUpdate = lazy(() =>
  import("./Agency/components/Charting/DischargeSummaryUpdate")
);
const CarePlanUpdate = lazy(() =>
  import("./Agency/components/Charting/CarePlanUpdate")
);
const ServiceAgreementEdit = lazy(() =>
  import("./Agency/components/Charting/ServiceAgreementEdit")
);
const AgencyProfile = lazy(() => import("./Agency/AgencyProfile"));
const SupervisorVisitViewUpdate = lazy(() =>
  import("./Agency/components/Charting/SupervisorVisitViewUpdate")
);
const AgencyReport = lazy(() => import("./Agency/AgencyReport"));
const InitialAssessmentUpdate = lazy(() =>
  import("./Agency/components/Charting/InitialAssessmentUpdate")
);
const PayCaregiver = lazy(() =>
  import("./Agency/components/ServiceRequest/PayCaregiver")
);
const PublishOnportal = lazy(() =>
  import("./Agency/components/ServiceRequest/PublishOnportal")
);
const AgencyAlerts = lazy(() =>
  import("./Agency/AgencyAlerts")
);
const AgencyClients = lazy(() =>
  import("./Agency/AgencyClients")
);
const AgencyMarketing = lazy(() =>
  import("./Agency/AgencyMarketing")
);
const AgencyMessages = lazy(() =>
  import("./Agency/AgencyMessages")
);

// nurses
const NursesLoginPage = lazy(() => import("./Nurses/NursesLogin"));
const NursesSignupPage = lazy(() => import("./Nurses/signupNurses"));
const NursesSideBar = lazy(() => import("./Nurses/NursesSideBar"));
const NursesDashboard = lazy(() => import("./Nurses/NursesDashboard"));
const NursesProfile = lazy(() => import("./Nurses/NursesProfile"));
const NursesServiceRequest = lazy(() =>
  import("./Nurses/NursesServiceRequest")
);
const NursesJobList = lazy(() => import("./Nurses/NursesJobList"));
const NursesSchedule = lazy(() => import("./Nurses/NursesSchedule"));
const NursesOpenNursesVisit = lazy(() =>
  import("./Nurses/NursesOpenNursesVisit")
);
const NursesEarnings = lazy(() => import("./Nurses/NursesEarnings"));
const NursesAlerts = lazy(() => import("./Nurses/NursesAlerts"));
const NursesReports = lazy(() => import("./Nurses/NursesReports"));
const NursesMessages = lazy(() => import("./Nurses/NursesMessages"));
const MySignatures = lazy(() =>
  import("./Nurses/components/Dashboard/MySignatures")
);
const InitialAssessment = lazy(() =>
  import("./Nurses/components/ServiceRequest/InitialAssessment")
);
const BidInsight = lazy(() => import("./Nurses/components/JobList/BidInsight"));
const JobDescription = lazy(() =>
  import("./Nurses/components/JobList/JobDescription")
);

// const NewServiceRequest = lazy(() => import("./pages/new-service-request"));
// const InitialAssessment = lazy(() => import("./pages/initial-assessment"));
// const PatientsDetails = lazy(() => import("./pages/patients-details"));
// const FinancialReports = lazy(() => import("./pages/financial-reports"));
// const SchedulingReports = lazy(() => import("./pages/scheduling-reports"));
// const ReportsVisits = lazy(() => import("./pages/reports-visits-by-caregiver"));
// const ClientReports = lazy(() => import("./pages/client-reports"));
// const ReportsADL = lazy(() => import("./pages/reports-adl"));
// const ReportsClientADLProvided = lazy(() =>
//   import("./pages/reports-client-adls-provided")
// );
// const ReportsCareNotes = lazy(() => import("./pages/reports-care-notes"));
// const ReportsAssignmentSheet = lazy(() =>
//   import("./pages/reports-assignment-sheet")
// );
// const ReportsAvailabilityBlocks = lazy(() =>
//   import("./pages/reports-availability-blocks")
// );
// const ReportsCaregiverCredentials = lazy(() =>
//   import("./pages/reports-caregiver-credentials")
// );
// const ReportsCaregiverShifts = lazy(() =>
//   import("./pages/reports-caregiver-shifts")
// );

// const ForgotPasswordPage = lazy(() => import("./Agency/forgot-password"));
// const ResetPasswordPage = lazy(() => import("./Agency/reset-password"));

const AllRoutes = () => {
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isChatBotPopupShow, setIsChatBotPopupShow] = useState(false);
  const [isHeaderSidebarShow, setIsHeaderSidebarShow] = useState(true);

  useEffect(() => {
    if (location.pathname.includes("/nurses-")) {
      setIsHeaderSidebarShow(true);
    } else {
      setIsHeaderSidebarShow(false);
    }
  }, [location.pathname]);

  return (
    <div className="">
      <div
        className={`app-container app-theme-dark body-tabs-shadow fixed-sidebar fixed-header ${
          location.pathname !== "/" &&
          location.pathname !== "/agency/signup" &&
          location.pathname !== "/agency/login" &&
          location.pathname !== "/nurses/signup" &&
          location.pathname !== "/nurses/login" &&
          location.pathname !== "/forgot-password" &&
          location.pathname !== "/reset-password"
            ? isSidebarOpen
              ? ""
              : "closed-sidebar "
            : ""
        }`}
      >
        {location.pathname !== "/" &&
        location.pathname !== "/agency/signup" &&
        location.pathname !== "/agency/login" &&
        location.pathname !== "/nurses/signup" &&
        location.pathname !== "/nurses/login" &&
        location.pathname !== "/forgot-password" &&
        location.pathname !== "/reset-password" ? (
          <AgencyHeader
            setIsSidebarOpen={setIsSidebarOpen}
            isSidebarOpen={isSidebarOpen}
          />
        ) : (
          ""
        )}
        <div
          className={`${
            location.pathname !== "/" &&
            location.pathname !== "/agency/signup" &&
            location.pathname !== "/agency/login" &&
            location.pathname !== "/nurses/signup" &&
            location.pathname !== "/nurses/login" &&
            location.pathname !== "/forgot-password" &&
            location.pathname !== "/reset-password"
              ? "app-main"
              : ""
          }`}
        >
          {/* {location.pathname !== "/" ? <AgencySideBar /> : ""} */}
          {location.pathname !== "/" &&
          location.pathname !== "/agency/signup" &&
          location.pathname !== "/agency/login" &&
          location.pathname !== "/nurses/signup" &&
          location.pathname !== "/nurses/login" &&
          location.pathname !== "/forgot-password" &&
          location.pathname !== "/reset-password" ? (
            isHeaderSidebarShow ? (
              <NursesSideBar />
            ) : (
              <AgencySideBar />
            )
          ) : (
            ""
          )}
          <Routes>
            <Route path="/" element={<AgencyLoginPage />} />
            <Route path="/agency/signup" element={<AgencySignupPage />} />
            <Route path="/agency/login" element={<AgencyLoginPage />} />
            {/* <Route path="/forgot-password" element={<ForgotPasswordPage />} /> */}
            {/* <Route path="/reset-password" element={<ResetPasswordPage />} /> */}
            {/* <Route path="/chatbotfaq" element={<ChatbotFaq />} /> */}

            {/* agency */}
            <Route
              path="/agency-dashboard"
              element={
                <React.Suspense fallback={<>...</>}>
                  <AgencyDashboard />
                </React.Suspense>
              }
            />
            <Route
              path="/agency-servicerequest/:id"
              element={
                <React.Suspense fallback={<>...</>}>
                  <AgencyServiceRequest />
                </React.Suspense>
              }
            />
            <Route
              path="/agency-servicerequest/addnewrequest/:id"
              element={
                <React.Suspense fallback={<>...</>}>
                  <AddNewRequest />
                </React.Suspense>
              }
            />
            <Route
              path="/agency-nurses/:id"
              element={
                <React.Suspense fallback={<>...</>}>
                  <AgencyNurses />
                </React.Suspense>
              }
            />
            <Route
              path="/agency-nurses/addnewrequest/:id"
              element={
                <React.Suspense fallback={<>...</>}>
                  <NursesNewRequest />
                </React.Suspense>
              }
            />
            <Route
              path="/agency-charting/:id"
              element={
                <React.Suspense fallback={<>...</>}>
                  <AgencyCharting />
                </React.Suspense>
              }
            />
            <Route
              path="/agency-charting/dischargesummary/update"
              element={
                <React.Suspense fallback={<>...</>}>
                  <DischargeSummaryUpdate />
                </React.Suspense>
              }
            />
            <Route
              path="/agency-charting/supervisorvisit/:id"
              element={
                <React.Suspense fallback={<>...</>}>
                  <SupervisorVisitViewUpdate />
                </React.Suspense>
              }
            />
            <Route
              path="/agency-charting/careplan/:id"
              element={
                <React.Suspense fallback={<>...</>}>
                  <CarePlanUpdate />
                </React.Suspense>
              }
            />
            <Route
              path="/agency-charting/initialassessment/update"
              element={
                <React.Suspense fallback={<>...</>}>
                  <InitialAssessmentUpdate />
                </React.Suspense>
              }
            />
            <Route
              path="/agency-charting/serviceagreement/edit"
              element={
                <React.Suspense fallback={<>...</>}>
                  <ServiceAgreementEdit />
                </React.Suspense>
              }
            />
            <Route
              path="/agency-profile"
              element={
                <React.Suspense fallback={<>...</>}>
                  <AgencyProfile />
                </React.Suspense>
              }
            />
            <Route
              path="/agency-reports/:id"
              element={
                <React.Suspense fallback={<>...</>}>
                  <AgencyReport />
                </React.Suspense>
              }
            />
            <Route
              path="agency-servicerequest/completedassessment/paycaregiver"
              element={
                <React.Suspense fallback={<>...</>}>
                  <PayCaregiver />
                </React.Suspense>
              }
            />
            <Route
              path="/agency-postjob"
              element={
                <React.Suspense fallback={<>...</>}>
                  <PublishOnportal />
                </React.Suspense>
              }
            />
            <Route
              path="/agency-alert/:id"
              element={
                <React.Suspense fallback={<>...</>}>
                  <AgencyAlerts />
                </React.Suspense>
              }
            />
            <Route
              path="/agency-clients"
              element={
                <React.Suspense fallback={<>...</>}>
                  <AgencyClients />
                </React.Suspense>
              }
            />
            <Route
              path="/agency-marketing"
              element={
                <React.Suspense fallback={<>...</>}>
                  <AgencyMarketing />
                </React.Suspense>
              }
            />
            <Route
              path="/agency-messages"
              element={
                <React.Suspense fallback={<>...</>}>
                  <AgencyMessages />
                </React.Suspense>
              }
            />
            {/* nurses */}

            <Route path="/nurses/signup" element={<NursesSignupPage />} />
            <Route
              path="/nurses-profile/:id"
              element={
                <React.Suspense fallback={<>...</>}>
                  <NursesProfile />
                </React.Suspense>
              }
            />
            <Route path="/nurses/login" element={<NursesLoginPage />} />
            <Route
              path="/nurses-profile/:id"
              element={
                <React.Suspense fallback={<>...</>}>
                  <NursesProfile />
                </React.Suspense>
              }
            />
            <Route path="/nurses/login" element={<NursesLoginPage />} />
            <Route
              path="/nurses-dashboard"
              element={
                <React.Suspense fallback={<>...</>}>
                  <NursesDashboard />
                </React.Suspense>
              }
            />
            <Route
              path="/nurses-servicerequest/:id"
              element={
                <React.Suspense fallback={<>...</>}>
                  <NursesServiceRequest />
                </React.Suspense>
              }
            />
            <Route
              path="/nurses-joblist/:id"
              element={
                <React.Suspense fallback={<>...</>}>
                  <NursesJobList />
                </React.Suspense>
              }
            />
            <Route
              path="/nurses-schedule/:id"
              element={
                <React.Suspense fallback={<>...</>}>
                  <NursesSchedule />
                </React.Suspense>
              }
            />
            <Route
              path="/nurses-openvisit"
              element={
                <React.Suspense fallback={<>...</>}>
                  <NursesOpenNursesVisit />
                </React.Suspense>
              }
            />
            <Route
              path="/nurses-earnings"
              element={
                <React.Suspense fallback={<>...</>}>
                  <NursesEarnings />
                </React.Suspense>
              }
            />
            <Route
              path="/nurses-alerts"
              element={
                <React.Suspense fallback={<>...</>}>
                  <NursesAlerts />
                </React.Suspense>
              }
            />
            <Route
              path="/nurses-reports/:id"
              element={
                <React.Suspense fallback={<>...</>}>
                  <NursesReports />
                </React.Suspense>
              }
            />
            <Route
              path="/nurses-messages"
              element={
                <React.Suspense fallback={<>...</>}>
                  <NursesMessages />
                </React.Suspense>
              }
            />
            <Route
              path="/nurses-servicerequest/mysignatures"
              element={
                <React.Suspense fallback={<>...</>}>
                  <MySignatures />
                </React.Suspense>
              }
            />
            <Route
              path="/nurses-servicerequest/initialassessment/:id"
              element={
                <React.Suspense fallback={<>...</>}>
                  <InitialAssessment />
                </React.Suspense>
              }
            />
            <Route
              path="/nurses-joblist/bidinsight"
              element={
                <React.Suspense fallback={<>...</>}>
                  <BidInsight />
                </React.Suspense>
              }
            />
            <Route
              path="/nurses-joblist/jobdescription"
              element={
                <React.Suspense fallback={<>...</>}>
                  <JobDescription />
                </React.Suspense>
              }
            />

            {/* <Route
              path="/new-service-request"
              element={
                <React.Suspense fallback={<>...</>}>
                  <NewServiceRequest />
                </React.Suspense>
              }
            ></Route> */}
            {/* <Route
              path="/initial-assessment"
              element={
                <React.Suspense fallback={<>...</>}>
                  <InitialAssessment />
                </React.Suspense>
              }
            ></Route> */}
            {/* <Route
              path="/patients-details"
              element={
                <React.Suspense fallback={<>...</>}>
                  <PatientsDetails />
                </React.Suspense>
              }
            ></Route> */}
            {/* <Route
              path="/financial-reports"
              element={
                <React.Suspense fallback={<>...</>}>
                  <FinancialReports />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/scheduling-reports"
              element={
                <React.Suspense fallback={<>...</>}>
                  <SchedulingReports />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/reports-visits-by-caregiver"
              element={
                <React.Suspense fallback={<>...</>}>
                  <ReportsVisits />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/client-reports"
              element={
                <React.Suspense fallback={<>...</>}>
                  <ClientReports />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/reports-adl"
              element={
                <React.Suspense fallback={<>...</>}>
                  <ReportsADL />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/reports-client-adls-provided"
              element={
                <React.Suspense fallback={<>...</>}>
                  <ReportsClientADLProvided />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/reports-care-notes"
              element={
                <React.Suspense fallback={<>...</>}>
                  <ReportsCareNotes />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/reports-assignment-sheet"
              element={
                <React.Suspense fallback={<>...</>}>
                  <ReportsAssignmentSheet />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/reports-availability-blocks"
              element={
                <React.Suspense fallback={<>...</>}>
                  <ReportsAvailabilityBlocks />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/reports-caregiver-credentials"
              element={
                <React.Suspense fallback={<>...</>}>
                  <ReportsCaregiverCredentials />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/reports-caregiver-shifts"
              element={
                <React.Suspense fallback={<>...</>}>
                  <ReportsCaregiverShifts />
                </React.Suspense>
              }
            ></Route> */}
          </Routes>
          {/* <div
            className={`${
              location.pathname !== "/" ? "help-chat-btn" : "d-none"
            }`}
            onClick={() => {
              setIsChatBotPopupShow(!isChatBotPopupShow);
            }}
          >
            <svg
              width="57"
              height="57"
              viewBox="0 0 57 57"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M53.5115 25.5359V17.2335C53.5115 15.7533 52.9235 14.3336 51.8768 13.2869C50.8301 12.2402 49.4104 11.6521 47.9301 11.6521H31.186V7.99075C32.0371 7.2261 32.5813 6.12657 32.5813 4.89308C32.5813 3.78287 32.1403 2.71813 31.3552 1.9331C30.5702 1.14806 29.5055 0.707031 28.3953 0.707031C27.2851 0.707031 26.2203 1.14806 25.4353 1.9331C24.6502 2.71813 24.2092 3.78287 24.2092 4.89308C24.2092 6.12657 24.7534 7.2261 25.6046 7.99075V11.6521H8.86038C7.3801 11.6521 5.96045 12.2402 4.91373 13.2869C3.86702 14.3336 3.27898 15.7533 3.27898 17.2335V25.6001L3.07805 25.614C2.37448 25.6641 1.71606 25.9791 1.23549 26.4954C0.754916 27.0117 0.48791 27.691 0.488282 28.3963V33.9777C0.488282 34.7179 0.782301 35.4277 1.30566 35.951C1.82902 36.4744 2.53884 36.7684 3.27898 36.7684V50.7219C3.27898 52.2022 3.86702 53.6218 4.91373 54.6685C5.96045 55.7153 7.3801 56.3033 8.86038 56.3033H47.9301C49.4104 56.3033 50.8301 55.7153 51.8768 54.6685C52.9235 53.6218 53.5115 52.2022 53.5115 50.7219V36.7684C54.2517 36.7684 54.9615 36.4744 55.4849 35.951C56.0082 35.4277 56.3022 34.7179 56.3022 33.9777V28.5694C56.3346 28.1362 56.2658 27.7015 56.1013 27.2996C55.5376 25.9377 54.3292 25.6112 53.5115 25.5359ZM14.4418 28.3963C14.4418 25.3154 16.3171 22.8149 18.6278 22.8149C20.9385 22.8149 22.8139 25.3154 22.8139 28.3963C22.8139 31.4773 20.9385 33.9777 18.6278 33.9777C16.3171 33.9777 14.4418 31.4773 14.4418 28.3963ZM39.5525 45.1405C36.759 45.1321 17.2325 45.1405 17.2325 45.1405V39.5591C17.2325 39.5591 36.7701 39.5535 39.5636 39.5591L39.5525 45.1405ZM38.1627 33.9777C35.852 33.9777 33.9767 31.4773 33.9767 28.3963C33.9767 25.3154 35.852 22.8149 38.1627 22.8149C40.4734 22.8149 42.3487 25.3154 42.3487 28.3963C42.3487 31.4773 40.4734 33.9777 38.1627 33.9777Z"
                fill="white"
              />
            </svg>
          </div>
          <div
            className={`${
              location.pathname !== "/" && isChatBotPopupShow ? "d-block " : ""
            } chat-bot-outer-box`}
          >
            <div className="row">
              <div className="col-3 col-sm-2">
                <img
                  src="/image/icon/chat-icon-full.png"
                  className="img-responsive img-fluid chat-img"
                />
              </div>
              <div className="col-9 col-sm-10">
                <div className="chat-message-area">
                  <div className="chat-message-heading">Chatbot</div>
                  <div className="chat-message-desc">
                    Hi!! Grace do you need help with anything, i am your AI Bot
                    and would help you find anything you want
                  </div>
                  <div className="chat-faq-btn">
                    <NavLink
                      className="btn btn-faq"
                      to={"/chatbotfaq"}
                      onClick={() => setIsChatBotPopupShow(false)}
                    >
                      See frequently asked questions
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AllRoutes;
