import * as type from "./commonActionTypes";

const initialState = {
  personalInfoData: [],
  accountDetailsData: [],
  payRateData: [],
  payRateHistoryData: [],
  mailingInfoData: [],
  contactInfoData: [],
  workInfoData: [],
  educationInfoData:[],
  certificationInfoData:[]
};

const nursesProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_PERSONAL_INFO:
      return {
        ...state,
        personalInfoData: action.payload,
      };
    case type.GET_ACCOUNT_DETAILS:
      return {
        ...state,
        accountDetailsData: action.payload,
      };
    case type.GET_PAY_RATE:
      return {
        ...state,
        payRateData: action.payload,
      };
    case type.GET_PAY_RATE_HISTORY:
      return {
        ...state,
        payRateHistoryData: action.payload,
      };
    case type.GET_MAILING_INFO:
      return {
        ...state,
        mailingInfoData: action.payload,
      };
    case type.GET_CONTACT_INFO:
      return {
        ...state,
        contactInfoData: action.payload,
      };
    case type.GET_WORK_EXPERIENCE_INFO:
      return {
        ...state,
        workInfoData: action.payload,
      };
    case type.GET_EDUCATION_INFO:
      return {
        ...state,
        educationInfoData: action.payload,
      };
      case type.GET_CERTIFICATION_INFO:
      return {
        ...state,
        certificationInfoData: action.payload,
      };
    default:
      return state;
  }
};

export default nursesProfileReducer;
