import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import { BrowserRouter } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "./Nurses/assets/sass/style.css";
import "./Nurses/assets/css/main.css";
import "./Nurses/assets/css/stylesheet.css";
import "./Nurses/assets/css/themestyle.css";
import "./Nurses/assets/agency/css/stylesheet.css";
import "./Nurses/assets/css/responsive.css";
import "./Nurses/assets/css/jquery.beefup.css";
import "./Nurses/assets/css/customeCss.css";
import "./Agency/assets/css/service-new.css";
import "./Nurses/assets/css/dynamicTable.css";
import "./Agency/assets/css/service-new-changes.css";

import AllRoutes from "./AllRoutes";

const App = ({ loading }) => {
  return (
    <>
      <BrowserRouter>
        <AllRoutes />
        <Backdrop open={loading}>
          <CircularProgress  color="inherit" />
      </Backdrop>
      </BrowserRouter>
    </>
  );
};

const mapStateToProps = (state) => {
  const { common } = state;
  return {
    loading: common.loading || false,
  };
};

export default connect(mapStateToProps)(App);
