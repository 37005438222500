import { combineReducers } from "redux";
import commonReducer from "./helpers/commonReducer";
import { reducer as formReducer } from 'redux-form';
import chartingReducer from "./helpers/chartingReducer";
import nursesProfileReducer from "./helpers/NursesReducer";
import {reducer as toastrReducer} from 'react-redux-toastr'

const rootreducer = combineReducers({
    common: commonReducer,
    charting: chartingReducer,
    nursesProfile : nursesProfileReducer,
    form: formReducer,
    toastr: toastrReducer // <- Mounted at toastr.

});

export default rootreducer;