export const SET_LOADER = "SET_LOADER";
export const SET_FAILED_MSG = "SET_FAILED_MSG"
export const SET_SUCCESS_MSG = "SET_SUCCESS_MSG"
export const GET_TASK_TABLE = "GET_TASK_TABLE"
export const GET_PERSONAL_INFO = "GET_PERSONAL_INFO"
export const GET_ACCOUNT_DETAILS = "GET_ACCOUNT_DETAILS"
export const GET_PAY_RATE = "GET_PAY_RATE"
export const GET_PAY_RATE_HISTORY = "GET_PAY_RATE_HISTORY"
export const GET_MAILING_INFO = "GET_MAILING_INFO"
export const GET_CONTACT_INFO = "GET_CONTACT_INFO"
export const GET_WORK_EXPERIENCE_INFO = "GET_WORK_EXPERIENCE_INFO"
export const GET_EDUCATION_INFO = "GET_EDUCATION_INFO"
export const GET_CERTIFICATION_INFO = "GET_CERTIFICATION_INFO"
