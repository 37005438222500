import * as type from "./commonActionTypes";

const initialState = {
  taskTableData: [],
};

const chartingReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_TASK_TABLE:
      return {
        ...state,
        taskTableData: action.payload,
      };
    default:
      return state;
  }
};

export default chartingReducer;
